/* @import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

/************ DOCUMENT STYLES ****************/
html {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: "Prompt", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	letter-spacing: 1px;
	overflow-x: hidden;
	background-color: #fff;
	--scroll-behavior: smooth!important;
	scroll-behavior: smooth!important;
}



body {
	height: 100%;
	font-family: "Prompt", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	background-color: #fff;
	margin: 0;
}

/* for Description going out of Box*/
li p {
	word-break: break-word;
	white-space: normal;
}

p {
	word-break: break-word;
	white-space: normal;
}


#__next {
	height: 100%;
}

button:focus,
select:focus,
textarea:focus {
	/* box-shadow: 0px 0px 0px 2px rgba(251, 88, 80, 0.2) !important; */
}

/************ CARD STYLES ****************/
.card {
	box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
	border-radius: 30px;
}

/************ TOASTER STYLES ****************/
.Toastify__toast--error {
	background: rgba(234, 76, 45, 1) !important;
	border: 2px solid rgba(234, 78, 45, 1) !important;
	backdrop-filter: blur(100px) !important;
	border-radius: 20px !important;
}

/* .Toastify__toast--error::after {
    content: url('../toast/close.svg');
    position: absolute;
    left: 265px;
    top: 22%;
} */

.Toastify__toast--error::before {
	content: url("../toast/error_fallback.svg");
	position: relative;
	z-index: 100000;
	left: 0px;
	top: 6px;
}

.Toastify__toast--success {
	background: rgba(56, 179, 89, 1) !important;
	border: 2px solid rgba(56, 179, 89, 1) !important;
	backdrop-filter: blur(100px) !important;
	border-radius: 20px !important;
}

.Toastify__toast--success::before {
	content: url("../toast/success_fallback.svg");
	position: relative;
	z-index: 100000;
	left: 12px;
	top: 6px;
}

/* .Toastify__toast--success::after {
    content: url('../toast/close.svg');
    position: absolute;
    left: 265px;
} */

.accordion-button:not(.collapsed) {
    color: #363a3f !important;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
	.Toastify__toast--success {
		background: rgba(56, 179, 89, 0.7) !important;
		border: 2px solid rgba(56, 179, 89, 0.3) !important;
	}

	.Toastify__toast--error {
		background: rgba(234, 76, 45, 0.7) !important;
		border: 2px solid rgba(234, 78, 45, 0.3) !important;
		backdrop-filter: blur(100px) !important;
		border-radius: 20px !important;
	}

	.Toastify__toast--success::before {
		content: url("../toast/success.svg");
	}

	.Toastify__toast--error::before {
		content: url("../toast/error.svg");
	}
}

.Toastify__toast-body {
	font-size: 16px;
	width: 100%;
	font-weight: 400;
	margin-left: 25px !important;
}

.Toastify__toast>button>svg {
	display: none;
}

/************ TOP NAV BAR STYLE ****************/
.top-nav-bar {
	background-color: #232f3e;
	padding: 10px 0;
	z-index: 10 !important;
}

.hamburger-menu {
	width: 50px;
	font-size: 25px;
	cursor: pointer;
	z-index: 11;
	font-weight: bold !important;
}

.hamburger-menu::before {
	padding-top: 1px;
}

.nav-logo {
	height: 45px;
}

/* XS screens */
@media only screen and (max-width: 480px) {
	.nav-logo {
		padding-left: 0px;
	}
}

.nav-logo-signedout {
	height: 55px;
	padding-left: 0px;
}

/* SM screens */
@media only screen and (min-width: 480px) and (max-width: 768px) {
	.nav-logo {
		padding-left: 0px;
	}
}

/************ SIDE BAR STYLES ****************/
.bm-menu-wrap {
	z-index: 10 !important;
	background-color: #232f3e;
	height: calc(100% - 75px) !important;
	top: 75px;
}

.bm-menu .sidebar-intersect {
	background: url("/img/intersect.svg") left top no-repeat;
	background-size: cover;
	background-color: transparent;
	width: 20px;
	height: 20px;
	left: 80px;
	top: -6px;
}

.bm-menu .bm-item {
	outline: none;
}

.bm-menu .menu-items a {
	text-decoration: none;
}

.bm-menu .menu-items .menu-item {
	margin: 0 5px;
	transition: all 0.5s ease-out;
}

.bm-menu .menu-items .active {
	background: #fff;
	color: #000 !important;
	border-radius: 30px;
	padding: 30px 0 !important;
	margin: 0 8px;
	transition: all 0.5s ease-in;
}

.bm-menu .menu-icon {
	font-size: 25px;
}

.bm-menu .menu-name {
	font-size: 10px;
}

.bm-menu .help {
	bottom: 0;
}

.bm-menu .help .menu-item {
	border-radius: 30px 30px 0 0;
	background: rgba(255, 255, 255, 0.2);
	margin: 0 10px;
}

/************ LOGGED-IN CONTAINER STYLES ****************/
.content-container {
	position: relative;
	height: 100%;
	background: #eee;
}

.signedin {
	min-height: 100%;
	height: auto;
}

#email-notification-button:disabled {
	background: #f39d58;
}

/* XS screens */
@media only screen and (max-width: 480px) {
	.signedin {
		width: 100%;
		left: 0;
	}
}

/* SM screens */
@media only screen and (min-width: 480px) and (max-width: 768px) {
	.signedin {
		width: 100%;
		left: 0;
	}
}

/* MD screens */
@media only screen and (min-width: 768px) {
	.signedin {
		width: calc(100% - 80px);
		left: 80px;
	}
}

.signedout {
	min-height: 100%;
	height: auto;
	width: 100%;
	left: 0;
}

/************ VERTICAL CENTERING CLASSES ****************/
.relative-center {
	top: 50%;
	position: relative;
	transform: translateY(-50%);
}

.vertical-center {
	top: 50%;
	transform: translateY(-50%);
}

/************ MODAL WINDOW STYLES ****************/
.ReactModal__Overlay {
	background-color: rgba(0, 0, 0, 0.75) !important;
	backdrop-filter: blur(5px);
	opacity: 0;
	transform: translateX(-100px);
	transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
	z-index: 2000 !important;
	opacity: 1;
	transform: translateX(0px);
}

.react-modal-side {
	top: 0;
	left: 65%;
	width: 35%;
	height: 100%;
	background: #232f3e;
	box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
	border-radius: 0px;
	position: relative;
	color: #fff;
	padding: 20px;
	overflow: auto;
}

.react-modal {
	width: 40%;
	display: inline-block;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #ffffff;
	box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	position: relative;
	overflow: auto;
	max-height: calc(100% - 75px);
}

.payment-check-modal {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.timeout {
	width: 50%;
	overflow: hidden;
}

/* XS screens */
@media only screen and (max-width: 480px) {
	.react-modal {
		width: 80% !important;
	}

	.react-modal-side {
		width: 65% !important;
		left: 35% !important;
	}
}

/* SM screens */
@media only screen and (min-width: 480px) and (max-width: 768px) {
	.react-modal {
		width: 80% !important;
	}

	.react-modal-side {
		width: 65% !important;
		left: 35% !important;
	}
}

/* MD screens */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.react-modal {
		width: 60% !important;
	}
}

/* lg screens */
@media only screen and (min-width: 1200px) {
	.timeout {
		width: 540px;
		overflow: hidden;
	}
}

.react-modal:focus,
.react-modal-side:focus {
	border: none;
	outline: none;
}

.react-modal .react-modal-title .react-modal-side .react-modal-side-title {
	font-weight: bold;
	font-size: 24px;
}

.react-modal .react-modal-close .react-modal-side .react-modal-side-close {
	font-size: 24px;
	font-weight: bold;
}

/************ USER MENU & DROP DOWN STYLES ****************/
.dropdown-menu {
	font-size: 16px;
	border-radius: 10px;
	border: none;
	margin-top: 25px !important;
	z-index: 1021;
}

.dropdown-menu .user-menu-item {
	display: block;
	padding: 15px 24px;
	color: #fff;
	text-decoration: none;
	cursor: pointer;
	overflow: hidden;
}

.dropdown-menu .user-menu-item:hover {
	background: #232f3e;
}

.dropdown-menu div:first-child .user-menu-item:hover {
	border-radius: 10px;
}

.dropdown-menu div:last-child .user-menu-item:hover {
	border-radius: 0 0 10px 10px;
}

.dropdown-menu-position {
	transform: translate3d(-66px, 18px, 0px) !important;
}

.dropdown-menu-arrow-wrapper {
	position: relative;
	width: 0;
	height: 0;
	top: 2px;
}

.dropdown-item {
	cursor: pointer;
}

.dropdown-item:focus,
.dropdown-item:hover {
	background: #fb5850;
	color: white;
}

.menu-items-wrapper {
	margin-top: -18px;
}

.submenu-menu-arrow-wrapper {
	left: 80%;
}

.dropdown-menu-arrow {
	top: -39px;
	left: 90px;
	width: 0;
	height: 0;
	position: absolute;
	display: inline;
}

.dropdown-menu-arrow:after {
	content: "";
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	border-width: 15px 12px;
	border-style: solid;
	border-color: transparent;
	z-index: 1001;
	border-bottom-color: #ffffff;
}

.app-dropdown-menu-arrow-wrapper {
	left: -26px;
}

/************ POLICY PAGES ****************/
.policy-main-section {
	color: black;
	padding: 5% 10%;
	letter-spacing: normal;
}

.policy-heading {
	color: black;
	text-align: center;
	margin-bottom: 50px;
}

.policy-heading {
	font-weight: 700;
	color: #572148;
}

.cookie-section,
.privacy-section {
	text-align: left;
	margin-top: 4%;
	color: black;
}

.cookie-section-header h3,
.privacy-section-header h3 {
	margin-bottom: 20px;
	font-size: 24px;
	font-weight: 700;
	color: #572148;
}

.cookie-set-body span,
.privacy-section span,
.privacy-section li::marker,
.privacy-info-body li::marker {
	font-weight: 700;
	color: #572148;
}

.cookie-thirdParty-body li {
	margin-bottom: 2%;
}

.cookie-thirdParty-body span {
	display: block;
	margin-top: 5px;
}

.rdt_TableCol_Sortable:hover,
.rdt_TableCol_Sortable:focus {
	color: white !important;
}

/************ TEL INPUT / PHONE NUMBER INPUT STYLES ****************/

.intl-tel-input span,
.country-name {
	color: black !important;
}

.intl-tel-input .arrow {
	pointer-events: none;
}

.intl-tel-input.allow-dropdown .selected-flag {
	overflow-x: hidden;
	display: flex !important;
	justify-content: flex-start;
	padding-left: 6px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 .selected-flag .selected-dial-code {
	padding-left: 5px;
	padding-right: 3px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 .selected-flag .selected-dial-code {
	padding-left: 5.8px;
	padding-right: 3.8px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 .selected-flag .selected-dial-code {
	padding-right: 2px;
	padding-left: 8px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 .selected-flag .selected-dial-code {
	padding-right: 2px;
	padding-left: 11px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input {
	/* // padding-left: 92px; */
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
	pointer-events: none;
	height: 100%;
	display: flex !important;
	align-items: center;
	background: white;
	position: absolute;
	left: 43px;
	color: grey;
}

/************ Slick Slider CUSTOM STYLES ****************/
.slick-track {
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.slick-prev:before,
.slick-next:before {
	font-family: 'slick';
	color: transparent !important;
}

/************ TIPPY CUSTOM STYLES ****************/
.custom-tippy {
	background-color: #2b394a !important;
	border-radius: 10px !important;
	padding: 10px !important;
	color: #fff !important;
}

.tippy-box[data-placement^="top"]>.tippy-arrow:before {
	border-top-color: #2b394a !important;
	/* set your color here and use the !important property */
}

.tippy-box[data-placement^="bottom"]>.tippy-arrow:before {
	border-bottom-color: #2b394a !important;
	/* set your color here and use the !important property */
}

.tippy-box[data-placement^="right"]>.tippy-arrow:before {
	border-right-color: #2b394a !important;
	/* set your color here and use the !important property */
}

.tippy-box[data-placement^="left"]>.tippy-arrow:before {
	border-left-color: #2b394a !important;
	/* set your color here and use the !important property */
}

/************ FLAG DROPDOWN ****************/

.selected-flag:focus {
	outline: none;
	border: none;
}

/************ COOKIE NOTIFICAION ****************/
.cookie-not-con {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 2147483645;
	box-sizing: border-box;
	width: 100%;
	background-color: #ffffff;
	box-shadow: 0px 0px 10px #00000026;
}

.cookie-not-con p {
	margin-bottom: 0px;
}

p {
	margin-bottom: 0px;
}

/************ NEW LINE ISSUE ********************/
.line-clamp p {
	margin-bottom: 0;
}

.z-10000 {
	z-index: 10000 !important;
}

.phone-input-intel .intl-tel-input {
	width: 100% !important;
	border-radius: 224px !important;
   }
.phone-input-intel .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=tel] { 
	height:35px !important;
	border-radius: 2px !important;
}