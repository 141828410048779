/* Color Classes */
.text-primary {
    color: #001234 !important;
}

.text-secondary {
    color: #424242 !important;
}

.text-tertiary {
    color: #001a47 !important;
}

.text-paragraph {
    color: #292929 !important;
}

.text-pricing {
    color: #525252 !important;
}

.text-section {
    color: #101828 !important;
}
.text-white{
    color: #fff !important;
}

/* Font Size Classes */
.font-xs {
    font-size: 14px !important;
}

.font-sm {
    font-size: 16px !important;
}

.font-md {
    font-size: 20px !important;
}

.font-lg {
    font-size: 24px !important;
}

.font-xl {
    font-size: 32px !important;
}

.font-xxl {
    font-size: 48px !important;
}

.font-xxxl {
    font-size: 64px !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-figtree {
    font-family: 'Figtree' !important;
}