:root {
	--primaryRegistrationTheme: #002e6e;
}

*:disabled {
	cursor: not-allowed;
}

.orange {
	color: #fb5850;
}

/************ INPUT STYLES ****************/
input.form-control,
select.form-control {
	background: #ffffff;
	border: 1px solid #cccccc;
	border-radius: 5px;
	padding: 10px;
}

textarea {
	border: 1px solid #cccccc !important;
	background: #ffffff;
	border-radius: 7px !important;
}

input.form-control:focus {
	box-shadow: 0px 0px 0px 2px rgba(251, 88, 80, 0.2) !important;
}

/************ REACT TEL INPUT STYLES ****************/
.iti-flag {
	background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/img/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.iti-flag {
		background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/img/flags.png");
	}
}

/**************** CUSTOM RADIO BUTTON **************************/
.custom-radio-label,
.custom-radio-label-success,
.custom-radio-label-error {
	display: block;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.custom-radio-label-text {
	padding-left: 35px !important;
}

/* Hide the browser's default radio button */
.custom-radio-label input,
.custom-radio-label-success input,
.custom-radio-label-error input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Unchecked Radio Button Style */
.theme-radio {
	position: absolute;
	height: 25px;
	width: 25px;
	background-image: url("/img/controls/custom-radio-unchecked.svg");
	background-size: cover;
}

/* Checked Radio Button Style */
.custom-radio-label input:checked ~ .theme-radio {
	background-image: url("/img/controls/custom-radio-checked.svg");
	background-size: cover;
}

.custom-radio-label-success input:checked ~ .theme-radio {
	background-image: url("/img/controls/custom-radio-checked-success.svg");
	background-size: cover;
}

.custom-radio-label-error input:checked ~ .theme-radio {
	background-image: url("/img/controls/custom-radio-checked-error.svg");
	background-size: cover;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.theme-radio:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio-label input:checked ~ .theme-radio:after,
.custom-radio-label-success input:checked ~ .theme-radio:after,
.custom-radio-label-error input:checked ~ .theme-radio:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio-label .theme-radio:after,
.custom-radio-label-success .theme-radio:after,
.custom-radio-label-error .theme-radio:after {
	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
}

/**************** CUSTOM CHECK BOX **************************/
input[type="checkbox"] {
	display: none;
}

input[type="checkbox"] + label:before {
	background: #fafbfd;
	border: 1px solid #b5d6ff;
	border-radius: 3px;
	content: "\00a0";
	display: inline-block;
	font: 16px/1em Amazon Ember;
	height: 18px;
	margin: 0.1em 0.6em 0 0;
	padding: 0;
	vertical-align: top;
	width: 18px;
}

input[type="checkbox"]:checked + label:before {
	background: #fb5850;
	color: #fff;
	content: "\2713";
	text-align: center;
	border: 1px solid #fb5850;
}
.manage-profile-form input[type="checkbox"]:checked + label:before {
	background: #002e6e;
	color: #fff;
	content: "\2713";
	text-align: center;
	border: 0.77px solid #d0d5dd;
}

input[type="checkbox"]:focus + label::before {
	outline: rgb(59, 153, 252) auto 5px;
}

/************ BUTTON STYLES ****************/
button.btn {
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	border: none;
	font-size: 16px;
	font-weight: 700;
	padding: 10px 30px;
}

button.btn-theme:hover {
	color: #fff;
}
/****** Galaxy Fold ********/
@media only screen and (max-width: 281px) {
	button.btn {
		padding: 10px 15px;
	}
}

button.btn-theme {
	background: var(--primaryRegistrationTheme);
	color: #fff;
}
button.outline-btn {
	border: 1px solid var(--primaryRegistrationTheme);
	background: transparent;
	color: var(--primaryRegistrationTheme);
	border-radius: 5px;
	font-size: 16px;
	font-weight: 700;
	padding: 10px 30px;
	transition: 0.2s all;
}

button:disabled {
	cursor: not-allowed !important;
	pointer-events: all !important;
}

button.outline-btn:hover {
	background: white;
	color: var(--primaryRegistrationTheme);
}

.whatsapp-button {
	width: 346px;
	margin-top: 30px;
	background: #20b038;
	color: #ffffff;
	margin-bottom: 40px;
}

.no-box-shadow {
	box-shadow: none !important;
}

/************ PASSWORD MASK STYLES ****************/
.password-show {
	background: none !important;
	font-size: 22px;
	top: 50% !important;
	transform: translateY(-50%);
	margin-top: 0 !important;
	color: #5495f9 !important;
}

/************ HORIZONTAL RULES STYLES ****************/
hr {
	color: #dddddd;
}

/************ MAKE THE CUSOR POINTER ****************/
.pointer {
	cursor: pointer;
}

/************ REACT SELECT BOX ****************/
.react-select-padding__value-container {
	padding: 2px 5px !important;
}

.react-select-padding__control {
	background: #ffffff;
	border: 1px solid #cccccc;
	border-radius: 5px !important;
}

.react-select-padding__control:focus,
.react-select-padding__control--is-focused {
	box-shadow: 0px 0px 0px 2px rgba(251, 88, 80, 0.2) !important;
}

/*************** Partner ********************/

.booth-contact-box {
	width: 300px;
	font-family: "Figtree";
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
}

/*************** ANIMATIONS ******************/

@keyframes slideUp {
	0% {
		transform: translateY(100px);
	}
	100% {
		transform: translateY(0px);
	}
}

@keyframes slideDown {
	0% {
		transform: translateY(0px);
	}
	100% {
		transform: translateY(70px);
		visibility: hidden;
	}
}

/*************** BUTTON ******************/

.btn-demo {
	background: #572148 !important;
}

.btn-demo:hover {
	color: #572148;
	background: #fb5850 !important;
}

.btn:focus {
	box-shadow: 0 0 0 0.2rem rgb(33 43 87 / 25%) !important;
}
.btn-header:hover {
	color: white;
	background: var(--primaryRegistrationTheme);
}

a:has(.btn-header):hover {
	color: #572148;
}

.btn-header {
	background: var(--primaryRegistrationTheme);
	color: #fff;
	font-size: 18px;
	padding: 15px 25px;
	box-shadow: 3px 3px 25px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	white-space: nowrap;
}
.btn-register {
	background: #572148;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	padding: 5px 22px;
	box-shadow: 3px 3px 25px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	border: none;
}

/***************CAROUSEL STYLES*****************/
.rec-carousel-item {
	display: flex;
	flex-grow: 1;
}

.rec-arrow-left,
.rec-arrow-right {
	background: none !important;
	box-shadow: none !important;
	font-size: 20px !important;
	color: #572148 !important;
}

.rec-arrow-left:disabled,
.rec-arrow-right:disabled {
	color: #999 !important;
}

.rec-dot {
	width: 8px !important;
	height: 8px !important;
	background-color: #c5e2ff !important;
	box-shadow: none !important;
}

.rec-dot_active {
	background-color: #002e6e !important;
}

.usecases-card {
	background: #23313e;
	box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.25);
	border: 4px solid #ffffff;
	border-radius: 0.5px;
	margin: 20px;
}

@media only screen and (max-width: 766px) {
	.whatsapp-button {
		width: 336px;
	}
}

/********************* THIS IS FOR ALL IMAGES UPLOADED IN EDITOR *******************************/
.image-reference {
	overflow: auto;
}
