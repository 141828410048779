
/* Color Classes3 */
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

html {
	font-family: 'Figtree' !important;
}
body {
	font-family: 'Figtree' !important;
}
.text-primary {
    color: #001234 !important;
}

.text-secondary {
    color: #424242 !important;
}

.text-tertiary {
    color: #001a47 !important;
}

.text-paragraph {
    color: #292929 !important;
}

.text-pricing {
    color: #525252 !important;

}

/* Font Size Classes */
.font-xs {
    font-size: 14px;
}

.font-sm {
    font-size: 16px;
}
.font-sm-2 {
    font-size: 18px;
}
.font-md {
    font-size: 20px;
}

.font-lg {
    font-size: 24px;
}

.font-xl {
    font-size: 32px;
}

.font-xxl {
    font-size: 48px;
}

.font-xxxl {
    font-size: 64px;
}
/* font-family */
.figtree-family{
    font-family: 'Figtree' !important;
}
.font-weight-light {
    font-weight: 300; 
  }
  
  .fw-normal {
    font-weight: 400; 
  }
  
  .fw-medium {
    font-weight: 500; 
  }
  
  .fw-semibold {
    font-weight: 600; 
  }
  
  .fw-bold {
    font-weight: 700; 
  }
  
  .fw-extra-bold {
    font-weight: 800; 
  }
  
  .fw-black {
    font-weight: 900; 
  }
  
  